body {
    min-height: unset !important;
}

.mastcontainer-map {
    height: 100vh;
}

.no-mastnav {
  .actionbar {
    width: 100%;
  }
  .mastcontainer {
    padding-left: 0;
  }
}

.loader {
    text-align: center;
}

.loader,
.login-form {
    margin-top: 50vh;
    transform: translateY(-50%);

    &.center {
      margin: inherit;
      transform: inherit;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}

.login-form .logo {
    max-height: 18rem;
}

.version {
    position: absolute;
    right: 0;
    font-size: .7rem;
}


/* Make cool transitions for fullscreen mode */
.mastheader, .mastnav, .mastcontainer, .actionbar, .table-filters, .sideBar-container {
  transition: .4s ease-in-out;
}

.mastnav.fullscreen {
    left: -7rem !important;
}

.mastheader.fullscreen {
    top: -5rem !important;
}

.actionbar.fullscreen {
    top: 0 !important;
    width: 100% !important;
}

.mastcontainer.fullscreen {
    padding-top: 4.5rem !important;
    padding-left: 0 !important;
}

.table-filters.fullscreen {
    top: 5rem !important;
}

.sideBar-container.fullscreen {
  left: -6.5rem !important;
  top: -3.7rem !important;
  height: 109vh !important;
}

/* fullscreen switch */

.btn-fullscreen {
  position: absolute;
  bottom: 2rem;
  right: .5rem;
  font-size: 2rem !important;
}
.btn-resetviewport {
  position: absolute;
  bottom: 5rem;
  right: .5rem;
  font-size: 2rem !important;
}
.btn-sm {
  font-size: small !important;
}


/* media queries won't accept CSS variables... */


/* lg breakpoint 1024 px */
@media screen and (max-width: 1023px) {
  .btn-fullscreen {
    bottom: 6rem;
  }
  .btn-resetviewport {
    bottom: 9rem;
  }
}

@media screen and (max-width: 1023.98px) {
  .sideBar-container{
    padding-left: 0;
    padding-top: 0;
    margin-top: 8rem;
    bottom: 0;
    max-width: 100vw;
    width: 100vw;
    top: 30%;
  }
  .sideBar-content {
    max-width: 100vw;
  }
  .sideBar-container.fullscreen {
    left: 0 !important;
    top: 30% !important;
  }
}

@media screen and (min-width: 1280px) {
  .sideBar-container{
    padding-top: 9rem;
  }
  .sideBar-container.fullscreen {
    top: -4.15rem;
  }
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.creation-sidebar.fullscreen {
  left: -6.25rem !important;
  top: -4.5rem !important;
  transition-delay: 0.2s;
}

@media screen and (max-width: 1023.98px) {
  .creation-sidebar{
    padding-left: 0.5rem !important;
    padding-top: 17.8rem !important;
    transition-delay: 0.11s;
  }
  .creation-sidebar.fullscreen {
    transition-delay: 0.25s;
    top: -4.25rem !important;
    left: 0 !important;
  }
}

.sentry-error-embed-wrapper {
  padding-top: 7.5% !important;
  z-index: 1057 !important;
}

.navtabs-item a {
  padding-bottom: 0.25rem !important;
}

 .form-control-sm {
  padding: .125rem .8rem !important;
  min-height: 1.475rem !important;
  font-size: small !important;
 }

.sentry-error-embed {
 max-height: 100% !important;
}

.cell-fixed {
  width: 2rem !important;
  min-height: 0 !important;
}

.table-scroller {
  overflow-x: scroll !important;
}

.btn-only-icon {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
